import { promoFragment$data } from '../../../relay/__generated__/promoFragment.graphql';
import { Link } from '../../primitives/Link/Link';
import { Markdown } from '../../primitives/Markdown/Markdown';
import { Paragraph } from '../../primitives/Paragraph/Paragraph';
import classNames from 'classnames';
import { Icon } from '../../primitives/Icon/Icon';

export type PromoProps = {
    promo: promoFragment$data['attributes'];
};

export const Promo = ({ promo }: PromoProps) => {
    if (!promo) {
        return null;
    }

    const { route, text, buttonText, orangeBackground, darkBackground } = promo;
    return (
        <Link
            route={route?.data}
            className={classNames(
                'flex flex-col justify-center py-4 px-2 tablet:flex-row',
                orangeBackground ? 'bg-orange' : darkBackground ? 'bg-darkGreen' : 'bg-green',
            )}
        >
            <div className={classNames(darkBackground && 'text-white')}>
                <Markdown content={text} />
            </div>
            {buttonText && (
                <div className={'mt-1 flex flex-shrink-0 items-center tablet:ml-2 tablet:mt-0'}>
                    <Paragraph className={classNames('font-bold mr-2', darkBackground && 'text-green')}>
                        {buttonText}
                    </Paragraph>
                    <Icon name={'arrowRight'} className={classNames(darkBackground && 'text-green')} />
                </div>
            )}
        </Link>
    );
};
