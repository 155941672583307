import { promoFragment$data } from '../../../relay/__generated__/promoFragment.graphql';
import { OmitFragmentType } from '../../../headless';
import { Promo } from '../../molecule/Promo/Promo';

export type PromosProps = {
    promos: ReadonlyArray<OmitFragmentType<promoFragment$data>>;
};

export const Promos = ({ promos }: PromosProps) => {
    return (
        <div>
            {promos.map((promo) => (
                <Promo key={promo.id} promo={promo.attributes} />
            ))}
        </div>
    );
};
