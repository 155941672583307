import styles from './KeyVisual.module.scss';
import clsx from 'clsx';
import { Image } from '../../primitives/Image/Image';
import man from '../../../../public/images/man.webp';
import { Shape, ShapeColors } from '../../primitives/Shape/Shape';

export const KeyVisual = () => {
    return (
        <div className={styles.widthWrapper}>
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <div className={clsx(styles.dimensions, styles.greenWrapper)}>
                        <Shape color={ShapeColors.green} className={styles.green} />
                    </div>
                    <div className={clsx(styles.dimensions, styles.firstOrangeWrapper)}>
                        <Shape color={ShapeColors.orange} className={styles.orange}>
                            <div className={styles.imageClip}>
                                <div className={styles.imageWrapper}>
                                    <Image
                                        src={man}
                                        width={473}
                                        height={582}
                                        alt={'placeholder'}
                                        sizes={'(max-width: 48rem) 15rem, (max-width: 64rem) 23.625rem, 29.5rem'}
                                        priority
                                    />
                                </div>
                            </div>
                        </Shape>
                    </div>
                    <div className={clsx(styles.dimensions, styles.secondOrangeWrapper)}>
                        <Shape color={ShapeColors.orange} className={styles.orange} />
                    </div>
                    <div className={clsx(styles.dimensions, styles.redWrapper, 'hidden tablet-landscape:block')}>
                        <Shape color={ShapeColors.red} className={styles.red} />
                    </div>
                    <div className={clsx(styles.dimensions, styles.redWrapper, 'tablet-landscape:hidden')}>
                        <Shape color={ShapeColors.green} className={styles.red} />
                    </div>
                </div>
            </div>
        </div>
    );
};
